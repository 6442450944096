import {
  ThornodeEndpoint,
  ThornodeV1Endpoint,
  ThornodeV2Endpoint,
} from "../config";

type ThornodeEndpointType =
  | typeof ThornodeEndpoint
  | typeof ThornodeV1Endpoint
  | typeof ThornodeV2Endpoint;

const selectThornodeEndpoint = (
  height: number | null,
): ThornodeEndpointType => {
  switch (true) {
    // If there is no height param, return the non-versioned Thornode endpoint.
    case height === null:
      return ThornodeEndpoint;
    // If the height is below the most recent versioned Thornode endpoint
    // return the previous version.
    case height !== null && height <= 17562000:
      return ThornodeV1Endpoint;
    // Otherwise return the most current version.
    default:
      return ThornodeV2Endpoint;
  }
};

export { selectThornodeEndpoint };
