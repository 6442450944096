export const APIEndpoint = "https://api.ninerealms.com";
export const ThornodeEndpoint = "https://thornode.ninerealms.com";
export const ThornodeV1Endpoint = "https://thornode-v1.ninerealms.com";
export const ThornodeV2Endpoint = "https://thornode-v2.ninerealms.com";
export const RPCEndpoint = "https://rpc.ninerealms.com";
export const MidgardEndpoint = "https://midgard.ninerealms.com";

// For chain height data
export const PoolModule = "thor1g98cy3n9mmjrpn0sxmn63lztelera37n8n67c0";
export const GenesisHeight = 4786560;
export const GenesisUnixTimestamp = 1647913096;

// Third-Party APIs
export const BinanceEndpoint = "https://api.binance.org/bc";
export const BlockCypherEndpoint = "https://api.blockcypher.com";
export const BTCComEndpoint = "https://chain.api.btc.com";
export const DefiLlamaEndpoint = "https://coins.llama.fi";
